export async function locationSearch(query) {
    const response = await fetch(`/.netlify/functions/locationSearch?query=${query}`);
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    const data = await response.json();
    return data;
}
export async function getTimezone(id) {
    const response = await fetch(`/.netlify/functions/timezone?id=${id}`);
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    const data = await response.json();
    return data;
}
