export const createTimeSlotArray = (startWorkingHour = 0, endWorkingHour = 0) => new Array(24).fill(false).map((_, i) => i >= startWorkingHour && i < endWorkingHour);
export const countSlots = (slotList) => slotList.filter((slot) => slot).length;
export const mergeSlotLists = (listA, listB) => {
    if (listA.length !== listB.length) {
        throw new Error('Lists must be of equal lengths');
    }
    return listA.map((value, index) => value && listB[index]);
};
export const shiftSlots = (slotList, offset) => {
    const adjustedOffset = offset > 0 ? offset : offset + slotList.length;
    return slotList.slice(adjustedOffset).concat(slotList.slice(0, adjustedOffset));
};
export const divideTimeSlots = (slotList, factor) => new Array(slotList.length * factor).fill(false).map((_, i) => slotList[Math.floor(i / factor)]);
export const utcOffsetToSlotShift = (utcOffsetMinutes, precision) => Math.floor(utcOffsetMinutes / (60 / precision));
export const countOverlap = (slotsA, slotsB, utcOffsetMinutesA, utcOffsetMinutesB, precision = 4) => {
    const dividedSlotsA = divideTimeSlots(slotsA, precision);
    const dividedSlotsB = divideTimeSlots(slotsB, precision);
    const offsetAInSlots = utcOffsetToSlotShift(utcOffsetMinutesA, precision);
    const offsetBInSlots = utcOffsetToSlotShift(utcOffsetMinutesB, precision);
    const adjustedA = shiftSlots(dividedSlotsA, offsetAInSlots);
    const adjustedB = shiftSlots(dividedSlotsB, offsetBInSlots);
    const overlapInSlots = countSlots(mergeSlotLists(adjustedA, adjustedB));
    const overlapInHours = overlapInSlots / precision;
    return overlapInHours;
};
